import { Base64 } from 'js-base64';

import Icon from '../Icon/Icon';
import Image from '../Image/Image';
import styles from './SideBySide.module.scss';

const SideBySide = ({ items = [] }) => {
    return (
        <div className={styles['SideBySide']}>
            <div className={styles['SideBySide__Container']}>
                <ul className={styles['SideBySide__List']}>
                    {items.map((item, index) => (
                        <Item key={index} {...item} isEven={index % 2 === 0} />
                    ))}
                </ul>
            </div>
        </div>
    );
};

const Item = ({
    title = '',
    text = '',
    link = {},
    images = [],
    isEven = false,
}) => {
    return (
        <li className={styles['SideBySide__Item']}>
            <a
                className={styles['SideBySide__Link']}
                href={link.href}
                target={link.target}>
                <span className={'sr-only'}>{link.title}</span>
            </a>
            <h2 className={styles['SideBySide__Title']}>{title}</h2>
            <div className={styles['SideBySide__Grid']}>
                {images.map((item, index) => (
                    <Content
                        key={index}
                        index={index}
                        isEven={isEven}
                        title={title}
                        {...item}
                    />
                ))}
            </div>
            <div className={styles['SideBySide__Bottom']}>
                <p className={styles['SideBySide__Text']}>{text}</p>
                <div className={styles['SideBySide__Icon']}>
                    <Icon icon={'arrowRight'} />
                </div>
            </div>
        </li>
    );
};

const Content = ({
    index = null,
    isEven = false,
    embed = '',
    title = '',
    image = {},
}) => {
    const isEmbed = !!embed;
    const embedMarkup = isEmbed ? { __html: Base64.decode(embed) } : null;

    return (
        <div className={styles['SideBySide__Content']}>
            {(isEven ? index % 2 === 0 : index % 2 !== 0) && (
                <h2 className={styles['SideBySide__ImageTitle']}>{title}</h2>
            )}
            {isEmbed ? (
                <div
                    className={styles['SideBySide__Video']}
                    dangerouslySetInnerHTML={embedMarkup}
                />
            ) : (
                <Image image={image} sizes={'50vw'} />
            )}
        </div>
    );
};

SideBySide.propTypes = {};

export default SideBySide;
